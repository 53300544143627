import React from "react";

export function Typography({
  children,
  fontSize,
  lineHeight,
  fontWeight = 500,
  color,
  letterSpacing,
  transition,
  style,
}) {
  return (
    <div
      style={{
        fontSize,
        lineHeight: `${lineHeight}px`,
        fontWeight,
        color,
        letterSpacing,
        transition,
        ...style,
      }}
    >
      {children}
    </div>
  );
}
