export const colors = {
  accent: {
    orange: {
      25: "#EC4F2C12",
      100: "#EC4F2C",
      125: "#CE3F20",
      150: "#B5371C",
    },
    green: {
      25: "#20C27512",
      100: "#20C275",
    },
  },
  text: {
    100: "#FAFAFA",
    125: "#B7BAC1",
    150: "#888C95",
    175: "#63666C",
    200: "#121212",
  },
  surface: {
    light: {
      100: "#FFFFFF",
      125: "#F5F6F8",
    },
    dark: {
      100: "#121212",
      125: "#161616",
    },
  },
  onSurface: {
    light: {
      25: "#AAB0B612",
      100: "#FFFFFF",
      125: "#E7E8EA",
      150: "#CFD1D5",
    },
    dark: {
      200: "#44474D",
      225: "#2A2B2E",
    },
  },
  divider: {
    100: "#E7E8EA",
    200: "#2A2B2E",
  },
};
